import { inject, Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
} from '@angular/router';

import { AuthService } from './auth.service';
import {
    KeycloakAuthGuardContext,
    KeycloakServiceContext,
} from './keycloak.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
    informationType,
    InformationsDialogComponent,
} from '../shared-components/session-expired-dialog/informations-dialog.component';
import { catchError } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuardContext {
    protected readonly router = inject(Router);
    protected readonly keycloak = inject(KeycloakServiceContext);
    private readonly dialog = inject(MatDialog);
    private readonly auth = inject(AuthService);

    constructor() {
        super(inject(Router), inject(KeycloakServiceContext)); // Super avec les injections
    }

    openInformationsDialog(informationType: informationType) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true; // Disable closing on backdrop click
        dialogConfig.hasBackdrop = true; // Enable backdrop
        dialogConfig.panelClass = ['wbce-dialog-pane'];
        dialogConfig.data = informationType;

        this.dialog.open(InformationsDialogComponent, dialogConfig);
    }

    isAccessAllowed(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
        return new Promise((resolve, reject) => {
            // Force the user to log in if currently unauthenticated.

            let permission;
            if (!this.auth.keycloakInit) {
                this.openInformationsDialog(informationType.KEYCLOAK_NOT_INIT);
                return reject(false);
            }

            if (!this.authenticated) {
                this.keycloak
                    .login({
                        redirectUri: window.location.origin + state.url,
                    })
                    .catch((e) => console.error(e));
                return reject(false);
            }

            const requiredRoles: string[] = route.data.roles;
            if (!requiredRoles || requiredRoles.length === 0) {
                permission = true;
            } else {
                if (!this.roles || this.roles.length === 0) {
                    permission = false;
                }
                if (
                    requiredRoles.every((role) => this.roles.indexOf(role) > -1)
                ) {
                    permission = true;
                } else {
                    permission = false;
                }
            }
            if (!permission) {
                this.router.navigate(['/']);
            }
            return this.auth
                .isLoggedIn()
                .pipe(
                    catchError((err) => {
                        this.openInformationsDialog(informationType.API_DOWN);
                        console.log(err);
                        return EMPTY;
                    })
                )
                .toPromise()
                .then(() => {
                    resolve(permission);
                }); //maybe reload user otherwise
        });
    }
}
