import { technos } from '../enums/technos';

export enum typeService {
    CMS = 'cms',
    FRONT = 'front',
    DATABASE = 'database',
    BACKEND = 'backend',
    WORKFLOW = 'workflow',
}

// segment technos
export interface StackData {
    id: number;
    description?: string;
    name: string;
    picture?: string;
    preview?: boolean;
    label?: string;
    content?: {
        type: typeService;
        techno: technos;
    }[];
}
