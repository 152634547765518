import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './service/auth.guard.keycloak';

const routes: Routes = [
    {
        path: 'connect',
        loadChildren: () =>
            import('./connect/connect.module').then((m) => m.ConnectModule),
    },
    {
        path: 'home',
        loadChildren: () =>
            import('./home/home.module').then((m) => m.HomeModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'workspace',
        loadChildren: () =>
            import('./workspace/workspace.module').then(
                (m) => m.WorkspaceModule
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'create',
        loadChildren: () =>
            import('./create-project/create-project.module').then(
                (m) => m.CreateProjectModule
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'create-server',
        loadChildren: () =>
            import('./create-server/create-server.module').then(
                (m) => m.CreateServerModule
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'create-account',
        loadChildren: () =>
            import('./create-account/create-account.module').then(
                (m) => m.CreateAccountModule
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'manage-account',
        loadChildren: () =>
            import('./manage-account/manage-account.module').then(
                (m) => m.ManageAccountModule
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'manage-workspace',
        loadChildren: () =>
            import('./manage-workspace/manage-workspace.module').then(
                (m) => m.ManageWorkspaceModule
            ),
        canActivate: [AuthGuard],
    },
    {
        path: '**',
        redirectTo: 'workspace',
    },
];

const routerOptions: ExtraOptions = {
    scrollPositionRestoration: 'enabled', // or 'top'
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64], // [x, y] - adjust scroll offset
};

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
